var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-12 col-md-12 col-sm-12" },
          [
            _c(
              "c-table",
              {
                ref: "table3",
                attrs: {
                  title: "LBL0002557",
                  columns: _vm.grid2.columns,
                  gridHeight: _vm.grid2.height,
                  data: _vm.data.rsltItemModelList,
                  filtering: false,
                  isExcelDown: false,
                  columnSetting: false,
                  usePaging: false,
                  editable: _vm.editable,
                  rowKey: "hazardousMachineryInspectionResultItemId",
                },
                scopedSlots: _vm._u([
                  {
                    key: "suffixTitle",
                    fn: function () {
                      return [
                        _c(
                          "font",
                          {
                            staticStyle: {
                              "font-size": "0.8em",
                              "font-weight": "300",
                            },
                          },
                          [
                            _vm._v(
                              "    " +
                                _vm._s(_vm.$label("LBL0002577")) +
                                "    " +
                                _vm._s(_vm.$label("LBL0002578")) +
                                "    " +
                                _vm._s(_vm.$label("LBL0002579")) +
                                "    " +
                                _vm._s(_vm.$label("LBL0002580")) +
                                "    "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "customArea",
                    fn: function ({ props }) {
                      return [
                        props.row.testMothod
                          ? _vm._l(
                              _vm.$_.split(props.row.testMothodName, ","),
                              function (name, index) {
                                return _c(
                                  "q-chip",
                                  {
                                    key: index,
                                    staticClass: "q-ma-none",
                                    attrs: {
                                      outline: "",
                                      square: "",
                                      color: "orange",
                                      "text-color": "white",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(name + " ") + " ")]
                                )
                              }
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.data.rsltItemModelList.length > 0
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveUrl2,
                                isSubmit: _vm.isSave2,
                                param: _vm.data.rsltItemModelList,
                                mappingType: "POST",
                                label: "LBLSAVE",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.save2,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }